@charset "UTF-8";
/**************************
Compass Text Replacement
*/
/**************************
Inline List
*/
/**************************
Inline Block List
*/
/**************************
horizontal-list
*/
/**************************
Bullets
*/
/* simple clearfix */
/**************************
@mixin for sprite
*/
html {
	font-size: 100%;
}

select,
input {
	font-size: 1rem;
}

.text_frame input,
.textarea_frame textarea {
	font-size: 1rem;
}

body {
	font-size: 0.875rem;
	line-height: 1.5;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************
## Layout
*/
.root {
	padding-top: 51px;
	overflow: hidden;
}

.wrapper {
	width: 100%;
	min-width: 100%;
}

.sp_container,
.mb_container,
.container {
	box-sizing: border-box;
	padding-left: 15px;
	padding-right: 15px;
	margin-left: auto;
	margin-right: auto;
}

.sp_container-nopadding,
.mb_container-nopadding,
.container-nopadding {
	padding-left: 0;
	padding-right: 0;
}

.sp_padding,
.padding {
	padding-left: 15px;
	padding-right: 15px;
}

.main_column {
	margin-bottom: 60px;
}

.sub_column {
	margin-left: -15px;
	margin-right: -15px;
	margin-bottom: 100px;
}

.root_container {
	overflow: hidden;
	word-wrap: break;
}

.scroll_to_top {
	right: 15px;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Utility - Cosmetic

ユーティリティー設定。
このサイト専用というわけでもない共通設定。

<div class="_h3">.sp</div>
PCの時だけ表示される。

<div class="_h3">.sp_block</div>
SPの時だけ、ブロック要素になる。

<div class="_h3">.tel_link</div>
SPの時だけ、リンクになる。(クリックで電話するリンク)

*/
.sp_none,
.root .sp_none {
	display: none;
}

.block,
.sp_block {
	display: block;
}

.sp_click {
	cursor: pointer;
}

.scell {
	display: block;
	margin-right: 0;
}

.tel_link-no {
	text-decoration: none;
	pointer-events: none;
	color: inherit;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Component - Base

コンポーネント設定。
各ページでよく使い回す記述。
このサイト専用の共通設定。

*/
/**************************

## - 段組設定 2列 3列 4列

SPの時だけ、中央に等間隔で余白を作り、width: 100%; を守って列になる。
ul でなくてもいい。

**2列 .sp_column2**

	<ul class="sp_column2">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**3列 .sp_column3**

	<ul class="sp_column3">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**4列 .sp_column4**

	<ul class="sp_column4">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

*/
.sp_column2::before, .sp_column2::after {
	content: '';
	display: table;
}

.sp_column2::after {
	clear: both;
}

.sp_column2 img {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.sp_column3::before, .sp_column3::after {
	content: '';
	display: table;
}

.sp_column3::after {
	clear: both;
}

.sp_column3 img {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.sp_column4::before, .sp_column4::after {
	content: '';
	display: table;
}

.sp_column4::after {
	clear: both;
}

.sp_column4 img {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.sp_column2 > *:nth-child(2n),
.sp_column3 > *:nth-child(3n),
.sp_column4 > *:nth-child(4n) {
	padding-bottom: 20px;
}

.sp_column2 > *:last-child,
.sp_column3 > *:last-child,
.sp_column4 > *:last-child {
	padding-bottom: 0;
}

.sp_column2::before, .sp_column2::after {
	content: '';
	display: table;
}

.sp_column2::after {
	clear: both;
}

.sp_column2 > * {
	width: 50%;
	float: left;
	box-sizing: border-box;
}

.sp_column2 > *:nth-child(2n + 1) {
	clear: left;
	padding-right: 8px;
}

.sp_column2 > *:nth-child(2n) {
	padding-left: 8px;
}

.sp_column3::before, .sp_column3::after {
	content: '';
	display: table;
}

.sp_column3::after {
	clear: both;
}

.sp_column3 > * {
	width: 33.33333%;
	float: left;
	box-sizing: border-box;
}

.sp_column3 > *:nth-child(3n + 1) {
	clear: left;
	padding-right: 10px;
}

.sp_column3 > *:nth-child(3n + 2) {
	padding-left: 5px;
	padding-right: 5px;
}

.sp_column3 > *:nth-child(3n) {
	padding-left: 10px;
}

.sp_column4::before, .sp_column4::after {
	content: '';
	display: table;
}

.sp_column4::after {
	clear: both;
}

.sp_column4 > * {
	width: 25%;
	float: left;
	box-sizing: border-box;
}

.sp_column4 > *:nth-child(4n + 1) {
	clear: left;
	padding-right: 11px;
}

.sp_column4 > *:nth-child(4n + 2) {
	padding-left: 4px;
	padding-right: 8px;
}

.sp_column4 > *:nth-child(4n + 3) {
	padding-left: 8px;
	padding-right: 4px;
}

.sp_column4 > *:nth-child(4n) {
	padding-left: 11px;
}

/**************************
.pc_column
*/
.pc_column2:not(.sp_column2):not(.mb_column2):not(.link_list) > *:not(:last-child) {
	margin-bottom: 1em;
}

.pc_column3:not(.sp_column2):not(.sp_column3):not(.mb_column2):not(.mb_column3):not(.link_list) > *:not(:last-child) {
	margin-bottom: 1em;
}

.pc_column4:not(.sp_column2):not(.sp_column3):not(.mb_column2):not(.mb_column3):not(.link_list) > *:not(:last-child) {
	margin-bottom: 1em;
}

.pc_column2_2 > *:not(:last-child) {
	margin-bottom: 1em;
}

/**************************
	.table-column2
*/
.table-column2 {
	width: 100%;
	max-width: 100%;
}

.table-column2:not(.table-noborder) tr:first-child th:first-child,
.table-column2:not(.table-noborder) tr:first-child td:first-child {
	border-top: 1px solid #ccc;
}

.table-column2 thead,
.table-column2 tbody,
.table-column2 tfoot,
.table-column2 tr,
.table-column2 th,
.table-column2 td {
	display: block;
	box-sizing: border-box;
	float: left;
	clear: both;
}

.root .table-column2 thead, .root
.table-column2 tbody, .root
.table-column2 tfoot, .root
.table-column2 tr, .root
.table-column2 th, .root
.table-column2 td {
	width: 100%;
}

.table-column2 tr th,
.table-column2 th + td {
	border-top: 0 none;
}

.table-column2 thead th,
.table-column2 thead td,
.table-column2 tbody th,
.table-column2 tbody td {
	width: 100%;
}

.table-column2 thead td + td,
.table-column2 tbody td + td {
	border-top: 0 none;
}

/*

<div class="_h3">横スクロールできる table</div>

responsive-tables.js と組み合わせないと効果が生まれない。
左の固定部分の横幅を変えたい場合は、CSSを追記する必要がある。
.responsive_table_area で包んで、
table.responsive とするのが必須の仕様。

	<div class="responsive_table_area">
		<table class="responsive">
			<tbody>
				<tr>
					<th>テキスト</th>
					<td>テキスト</td>
				</tr>
				<tr>
					<th>テキスト</th>
					<td>テキスト</td>
				</tr>
				<tr>
					<th>テキスト</th>
					<td>テキスト</td>
				</tr>
			</tbody>
		</table>
	</div>

*/
.responsive_table_area {
	position: relative;
	top: 0;
	left: 0;
	clear: both;
	margin-bottom: 10px;
}

.root .responsive_table_area table {
	margin-bottom: 0;
}

.responsive_table_area:not(.responsive_table_area-all) th:first-child {
	max-width: 125px;
	min-width: 125px;
	width: 125px;
}

.responsive_table_area:not(.responsive_table_area-all) .table-wrapper .pinned {
	max-width: 126px;
	min-width: 126px;
	width: 126px;
}

.responsive_table_area:not(.responsive_table_area-all) .table-wrapper .scrollable {
	padding-left: 125px;
}

.responsive_table_area:not(.responsive_table_area-all) .table-wrapper .scrollable > table {
	margin-left: -125px;
}

.responsive_table_area td {
	min-width: 70px;
}

.responsive_table_area th:first-child {
	box-sizing: border-box;
}

.responsive_table_area .scrollable {
	-webkit-overflow-scrolling: touch;
}

.responsive_table_area .table-wrapper {
	margin-bottom: 0;
}

.responsive_table_area table.responsive th:first-child,
.responsive_table_area table.responsive table.responsive td:first-child,
.responsive_table_area table.responsive table.responsive td:first-child,
.responsive_table_area table.responsive.pinned td {
	display: table-cell;
}

.responsive_table_area-all .table-wrapper .table {
	min-width: 720px;
}

.responsive_table_area-all .table-wrapper td,
.responsive_table_area-all .table-wrapper th {
	position: static;
	white-space: normal;
}

.responsive_table_area-all .table-wrapper .pinned {
	display: none;
}

.responsive_table_area-all .table-wrapper .scrollable {
	padding-left: 0;
}

.responsive_table_area-all .table-wrapper .scrollable > table {
	margin-left: 0;
}

.responsive_table_mask {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: url("../img/icon_swipe.png") center center no-repeat transparent;
	background-size: auto 90px;
	z-index: 102;
	cursor: pointer;
}

.responsive_table_mask.shadow {
	background-image: none;
	background: #666;
	opacity: 0.5;
	z-index: 101;
}

/**************************
	.table
*/
.no_th_table_area .table-wrapper .pinned {
	display: none;
}

.no_th_table_area .table-wrapper .scrollable {
	padding-left: 0;
}

.no_th_table_area .table-wrapper .scrollable > table {
	margin-left: -0;
}

.no_th_table_area th:first-child,
.no_th_table_area td:first-child {
	max-width: none;
	min-width: initial;
	width: auto;
}

.root .no_th_table_area th,
.root .no_th_table_area td {
	white-space: inherit !important;
}

/* form */
.input_frame.node,
.input_frame.node input {
	width: 60px;
	display: inline-block;
}

.input_frame.node2,
.input_frame.node2 input {
	width: 70px;
}

form .frame .node {
	margin-right: 3px;
}

.sp_center {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	display: block;
}

.sp_center .img {
	display: inline-block;
	margin-left: auto;
	margin-right: auto;
}

.set .fix + .resize,
.set .resize + .fix {
	margin-top: 10px;
}

.set .left,
.set .right {
	float: none;
}

.set .fix img,
.set .resize img {
	display: block;
	max-width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.footer-scroll_to_top_stop .scroll_to_top {
	position: absolute;
	bottom: 30px;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Project

特定ページ専用、効果範囲が限定的な記述。
コンテンツ内容に密接で、汎用性がなく、機能の付け替えを想定していない。

*/
.sp_wide {
	margin-left: -15px;
	margin-right: -15px;
}

.header {
	background-color: #fff;
	height: 51px;
	box-sizing: border-box;
	border-bottom: 1px solid #efefef;
	top: 0;
	left: 0;
}

.header__1 .container {
	height: 51px;
}

.header__logo {
	padding-top: 15px;
}

.header__logo a,
.header__logo img {
	display: block;
	width: 180px;
}

.header__sp_tel {
	text-indent: -119988px;
	overflow: hidden;
	text-align: left;
	background: url("../img/sp/header_tel.png") 0 0 no-repeat transparent;
	background-size: 50px 50px;
	width: 50px;
	height: 50px;
	position: absolute;
	top: 0;
	right: 50px;
	background-color: #eae6e4;
	z-index: 10;
}

.open_menu {
	text-indent: -119988px;
	overflow: hidden;
	text-align: left;
	margin: 0;
	width: 50px;
	height: 50px;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
	background-color: #392214;
}

.open_menu:hover {
	text-decoration: none;
	opacity: 0.7;
	transition: 0.2s opacity;
}

.open_menu::after {
	content: '';
	display: block;
	background: url("../img/sp/menu_open.png") 0 0 no-repeat transparent;
	background-size: 50px 50px;
	width: 50px;
	height: 50px;
	content: '';
	display: block;
	position: absolute;
	top: 0;
	left: 0;
}

.mm-opened .open_menu::after {
	background-image: url("../img/sp/menu_close.png");
}

.page_title_line {
	padding: 1.3em 0;
	margin-bottom: 34px;
}

.page_title_line h2 {
	font-size: 1.5rem;
}

.main_content img {
	max-width: 100%;
}

.main_content h2:not(.cancel),
.main_content h3:not(.cancel),
.main_content .h3-1,
.main_content .accordion_area,
.main_content .contact_box {
	margin-left: -15px;
	margin-right: -15px;
}

.main_content h3:not(.cancel),
.main_content .h3 {
	font-size: 1.125rem;
	padding: 0.35em 10px;
	line-height: 1.22222;
	margin-bottom: 20px;
}

.main_content .h3-1 {
	font-size: 1.125rem;
	line-height: 1.22222;
}

.main_content .h3-1::before {
	width: 8px;
	height: 8px;
	border-radius: 4px;
	top: 15px;
}

.main_content h4:not(.cancel),
.main_content .h4 {
	font-size: 1rem;
	line-height: 1.375;
	margin-bottom: 28px;
	border-left: 4px solid #f18d00;
	padding-left: 15px;
}

.main_content h4:not(.cancel)::before,
.main_content .h4::before {
	content: '';
	display: block;
	background-color: #a9a6a1;
	height: 1px;
	width: calc(100% + 4px);
	position: absolute;
	bottom: -8px;
	left: -4px;
}

.main_content h5:not(.cancel),
.main_content .h5 {
	font-size: 1rem;
	line-height: 1.375;
	padding-left: 20px;
}

.main_content h5:not(.cancel)::before,
.main_content .h5::before {
	content: '';
	display: block;
	background: url("../img/sp/h4.png") 0 0 no-repeat transparent;
	background-size: 16px 16px;
	width: 16px;
	height: 16px;
	top: 3px;
}

.main_content h6:not(.cancel),
.main_content .h6 {
	font-size: 0.875rem;
	margin-bottom: 1em;
}

.footer__body .container::before {
	left: 15px;
	width: 200px;
	height: 54px;
	background-size: contain;
	top: -54px;
}

.footer__logo {
	margin-bottom: 3px;
}

.footer__logo,
.footer__logo a,
.footer__logo img {
	width: 138px;
	height: 30px;
}

.footer__address {
	font-size: 0.813rem;
}

.footer__tel {
	margin-bottom: 15px;
}

.footer__tel,
.footer__tel a {
	width: 181px;
	height: 38px;
}

.footer__btn_area {
	max-width: 290px;
	margin-bottom: 15px;
}

.footer__btn_area li {
	width: 33%;
}

.footer__btn_area li:not(:last-child) {
	margin-right: 8px;
}

.footer__btn_area a {
	min-height: 30px;
	border-radius: 15px;
	padding-top: .5em;
	padding-bottom: .4em;
	padding-left: 7px;
	padding-right: 7px;
	font-size: 0.688rem;
}

.footer__btn_area a::before {
	content: '';
	display: inline-block;
	background: url("../img/icon/arrow/footer_btn.png") 0 center no-repeat transparent;
	background-size: 6px 10px;
	width: 6px;
	height: 10px;
	margin-right: 2px;
}

.footer__menu {
	margin-bottom: 60px;
}

.footer__menu a {
	font-size: 0.75rem;
	padding-top: .45em;
	padding-bottom: .45em;
	display: block;
}

.footer__foot {
	min-height: 20px;
	padding-top: 3px;
}

.scroll_to_top {
	bottom: -69px;
	right: 50%;
	margin-right: -32px;
}

.scroll_to_top,
.scroll_to_top a,
.scroll_to_top img {
	width: 64px;
	height: 64px;
}
