@charset "utf-8";
@import 'import/var';

html {
	font-size: 100%;
}

select,
input {
	font-size: $fontsize16;
}
.text_frame input,
.textarea_frame textarea {
	font-size: $fontsize16;
}

body {
	font-size: $fontsize14;
	line-height: 1.5;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************
## Layout
*/

.root {
	padding-top: $sp_header_height;
	overflow: hidden;
}

.wrapper {
	width: 100%;
	min-width: 100%;
}
@mixin container {
	box-sizing: border-box;
	padding-left: $sp_padding;
	padding-right: $sp_padding;
	margin-left: auto;
	margin-right: auto;
	// overflow: hidden;
}

.sp_container,
.mb_container,
.container {
	@include container;

	&-nopadding {
		padding-left: 0;
		padding-right: 0;
	}
}

.sp_padding,
.padding {
	padding-left: $sp_padding;
	padding-right: $sp_padding;
}

// .main_column {
// 	margin-bottom: 30px;
// }

.main_column {
	margin-bottom: 60px;
}

.sub_column {
	@include sp_unindent;
	margin-bottom: 100px;
}

.root_container {
	overflow: hidden;
	word-wrap: break;
}

.scroll_to_top {
	right: $sp_padding;
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Utility - Cosmetic

ユーティリティー設定。
このサイト専用というわけでもない共通設定。

<div class="_h3">.sp</div>
PCの時だけ表示される。

<div class="_h3">.sp_block</div>
SPの時だけ、ブロック要素になる。

<div class="_h3">.tel_link</div>
SPの時だけ、リンクになる。(クリックで電話するリンク)

*/
.sp_none,
.root .sp_none {
	display: none;
}
.block,
.sp_block {
	display: block;
}
.sp_click {
	cursor: pointer;
}
.scell {
	display: block;
	margin-right: 0;
}

.tel_link {

	// .root &,
	// .root &:hover {
	// 	text-decoration: underline;
	// 	color: palette('link');
	// }
	&-no {
		text-decoration: none;
		pointer-events: none;
		color: inherit;
	}
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Component - Base

コンポーネント設定。
各ページでよく使い回す記述。
このサイト専用の共通設定。

*/


/**************************

## - 段組設定 2列 3列 4列

SPの時だけ、中央に等間隔で余白を作り、width: 100%; を守って列になる。
ul でなくてもいい。

**2列 .sp_column2**

	<ul class="sp_column2">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**3列 .sp_column3**

	<ul class="sp_column3">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

**4列 .sp_column4**

	<ul class="sp_column4">
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
		<li>コンテンツ</li>
	</ul>

*/
$column: (2, 3, 4);

@each $num in $column {
	.sp_column#{$num} {
		@include clearfix;

		img {
			display: block;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.sp_column2 > *:nth-child(2n),
.sp_column3 > *:nth-child(3n),
.sp_column4 > *:nth-child(4n) {
	padding-bottom: 20px;
}
.sp_column2 > *:last-child,
.sp_column3 > *:last-child,
.sp_column4 > *:last-child {
	padding-bottom: 0;
}

.sp_column2 {
	@include columns(2, 15px);
}

.sp_column3 {
	@include columns(3, 15px);
}

.sp_column4 {
	@include columns(4, 15px);
}

/**************************
.pc_column
*/

.pc_column2:not(.sp_column2):not(.mb_column2):not(.link_list) {
	> *:not(:last-child) {
		margin-bottom: 1em;
	}
}
.pc_column3:not(.sp_column2):not(.sp_column3):not(.mb_column2):not(.mb_column3):not(.link_list) {
	> *:not(:last-child) {
		margin-bottom: 1em;
	}
}
.pc_column4:not(.sp_column2):not(.sp_column3):not(.mb_column2):not(.mb_column3):not(.link_list) {
	> *:not(:last-child) {
		margin-bottom: 1em;
	}
}

.pc_column2_2 {
	> *:not(:last-child) {
		margin-bottom: 1em;
	}
}


/**************************
	.table-column2
*/
.table {
	&-column2 {
		// display: block;
		width: 100%;
		max-width: 100%;
		&:not(.table-noborder) {
			tr:first-child {
				th:first-child,
				td:first-child {
					border-top: 1px solid palette('table', 'border');
				}
			}
		}

		thead,
		tbody,
		tfoot,
		tr,
		th,
		td {
			display: block;
			box-sizing: border-box;
			float: left;
			clear: both;

			.root & {
				width: 100%;
			}
		}
		tr th,
		th + td {
			border-top: 0 none;
		}
		thead,
		tbody {
			th,
			td {
				width: 100%;
			}
			td + td {
				border-top: 0 none;
			}
		}
	}
}

/*

<div class="_h3">横スクロールできる table</div>

responsive-tables.js と組み合わせないと効果が生まれない。
左の固定部分の横幅を変えたい場合は、CSSを追記する必要がある。
.responsive_table_area で包んで、
table.responsive とするのが必須の仕様。

	<div class="responsive_table_area">
		<table class="responsive">
			<tbody>
				<tr>
					<th>テキスト</th>
					<td>テキスト</td>
				</tr>
				<tr>
					<th>テキスト</th>
					<td>テキスト</td>
				</tr>
				<tr>
					<th>テキスト</th>
					<td>テキスト</td>
				</tr>
			</tbody>
		</table>
	</div>

*/

.responsive_table_area {
	position: relative;
	top: 0;
	left: 0;
	clear: both;
	margin-bottom: 10px;


	.root & table {
		margin-bottom: 0;
	}
	&:not(.responsive_table_area-all) {
		th {
			&:first-child {
				max-width: 125px;
				min-width: 125px;
				width: 125px;
			}
		}
		.table-wrapper {
			.pinned {
				max-width: 126px;
				min-width: 126px;
				width: 126px;
			}
			.scrollable {
				padding-left: 125px;
			}
			.scrollable > table {
				margin-left: -125px;
			}
		}
	}
	td {
		min-width: 70px;
	}
	th {
		&:first-child {
			box-sizing: border-box;
		}
	}
	.scrollable {
		-webkit-overflow-scrolling: touch;
	}
	.table-wrapper {
		margin-bottom: 0;
	}

	table.responsive {
		th:first-child,
		table.responsive td:first-child,
		table.responsive td:first-child,
		&.pinned td {
			display: table-cell;
		}
	}

	&-all {
		.table-wrapper {
			.table {
				min-width: $pc_main_content_width;
			}
			td,
			th {
				position: static;
				white-space: normal;
			}
			.pinned {
				display: none;
			}
			.scrollable {
				padding-left: 0;
				> table {
					margin-left: 0;
				}
			}
		}
	}
}

.responsive_table_mask {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: url( '../img/icon_swipe.png' ) center center no-repeat transparent;
	background-size: auto 90px;
	z-index: 102;
	cursor: pointer;
}

.responsive_table_mask.shadow {
	background-image: none;
	background: #666;
	opacity: 0.5;
	z-index: 101;
}


/**************************
	.table
*/

.no_th_table_area {
	.table-wrapper .pinned {
		display: none;
	}
	.table-wrapper .scrollable {
		padding-left: 0;
	}
	.table-wrapper .scrollable > table {
		margin-left: -0;
	}
	th:first-child,
	td:first-child {
		max-width: none;
		min-width: initial;
		width: auto;
	}
	.root & {
		th,
		td {
			white-space: inherit !important;
		}
	}
}

/* form */
.input_frame.node,
.input_frame.node input {
	width: 60px;
	display: inline-block;
}
.input_frame.node2,
.input_frame.node2 input {
	width: 70px;
}
form .frame .node {
	margin-right: 3px;
}


.sp_center {
	text-align: center;
	margin-left: auto;
	margin-right: auto;
	display: block;

	.img {
		display: inline-block;
		margin-left: auto;
		margin-right: auto;
	}
}

.set {
	.fix + .resize,
	.resize + .fix {
		margin-top: 10px;
	}

	.left,
	.right {
		float: none;
	}

	.fix,
	.resize {
		img {
			display: block;
			max-width: 100%;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.footer {
	&-scroll_to_top_stop {
		.scroll_to_top {
			position: absolute;
			bottom: 30px;
		}
	}
}

/***********************************************************************
 ***********************************************************************
 ***********************************************************************

## Project

特定ページ専用、効果範囲が限定的な記述。
コンテンツ内容に密接で、汎用性がなく、機能の付け替えを想定していない。

*/

.sp_wide {
	@include sp_unindent;
}

.header {
	background-color: #fff;

	height: $sp_header_height;
	box-sizing: border-box;
	border-bottom: 1px solid #efefef;
	top: 0;
	left: 0;
	// box-shadow: 0 1px 2px 0 rgba(#000, .08);

	&__1 {
		.container {
			height: $sp_header_height;
		}
	}

	&__logo {
		padding-top: 15px;
		a,
		img {
			display: block;
			width: 180px;
		}
	}
	&__sp_tel {
		@include hide-text;
		@include bg('../img/sp/header_tel.png', 50px, 50px);
		position: absolute;
		top: 0;
		right: 50px;
		background-color: #eae6e4;
		z-index: 10;
	}
}
.open_menu {
	@include hide-text;
	@include on_opacity;
	margin: 0;
	width: 50px;
	height: 50px;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 10;
	background-color: #392214;

	&::after {
		@include bg-block('../img/sp/menu_open.png', 50px, 50px);
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;

		.mm-opened & {
			background-image: url('../img/sp/menu_close.png');
		}
	}
}

.page_title_line {
	padding: 1.3em 0;
	margin-bottom: 34px;

	h2 {
		font-size: $fontsize24;
	}
}

.main_content {
	img {
		max-width: 100%;
	}

	h2:not(.cancel),
	h3:not(.cancel),
	.h3-1,
	.accordion_area,
	.contact_box {
		@include sp_unindent;
	}

	h3:not(.cancel),
	.h3 {
		font-size: $fontsize18;
		padding: 0.35em 10px;
		line-height: (22 / 18);
		margin-bottom: 20px;
	}

	.h3 {
		&-1 {
			font-size: $fontsize18;
			line-height: (22 / 18);
			&::before {
				width: 8px;
				height: 8px;
				border-radius: 4px;
				top: 15px;
			}
		}
	}

	h4:not(.cancel),
	.h4 {
		$border_width: 4px;
		$border_padding: 8px;

		font-size: $fontsize16;
		line-height: (22 / 16);
		margin-bottom: $border_padding + 20px;
		border-left: $border_width solid palette('orange', 'default');
		padding-left: 15px;

		&::before {
			content: '';
			display: block;
			background-color: #a9a6a1;
			height: 1px;
			width: calc(100% + #{$border_width});
			position: absolute;
			bottom: - $border_padding;
			left: - $border_width;
		}
	}


	h5:not(.cancel),
	.h5 {
		font-size: $fontsize16;
		line-height: (22 / 16);
		padding-left: 20px;
		&::before {
			@include bg-block('../img/sp/h4.png', 16px, 16px);
			top: 3px;
		}
	}
	h6:not(.cancel),
	.h6 {
		font-size: $fontsize14;
		margin-bottom: 1em;
	}
}

.footer {
	&__body {
		.container {
			&::before {
				left: 15px;
				width: 200px;
				height: 54px;
				background-size: contain;
				top: -54px;
			}
		}
	}
	&__logo {
		margin-bottom: 3px;
		&,
		a,
		img {
			width: 138px;
			height: 30px;
		}
	}
	&__address {
		font-size: $fontsize13;
	}
	&__tel {
		margin-bottom: 15px;
		&,
		a {
			width: 181px;
			height: 38px;
		}
	}
	&__btn_area {
		max-width: 290px;
		margin-bottom: 15px;
		li {
			width: 33%;
			&:not(:last-child) {
				margin-right: 8px;
			}
		}
		a {
			min-height: 30px;
			border-radius: 15px;
			padding-top: .5em;
			padding-bottom: .4em;
			padding-left: 7px;
			padding-right: 7px;
			font-size: $fontsize11;

			&::before {
				@include bg-inline('../img/icon/arrow/footer_btn.png', 6px, 10px);
				margin-right: 2px;
			}
		}
	}
	&__menu {
		margin-bottom: 60px;
		a {
			font-size: $fontsize12;
			padding-top: .45em;
			padding-bottom: .45em;
			display: block;
		}
	}
	&__foot {
		min-height: 20px;
		padding-top: 3px;
	}
}

.scroll_to_top {
	bottom: -64px - 5px;
	right: 50%;
	margin-right: -32px;

	&,
	a,
	img {
		width: 64px;
		height: 64px;
	}
}

